












































import { Vue, Component } from 'vue-property-decorator';
import { API } from '@/types';
import { apiGETMulti, axiosConfig } from '@/api';
import axios from 'axios';

@Component
export default class extends Vue {
  livesplitData: API.LiveSplit.Data.GET[] = [];
  selectedConnectionID: number | null = null;
  selectedKey = ''; // Cannot be undefined otherwise it's not defined (duh).

  get connections(): number[] {
    return this.livesplitData.map((d) => d.playerId);
  }

  get selectedConnectionData(): API.LiveSplit.Data.GET | undefined {
    return this.livesplitData.find((d) => d.playerId === this.selectedConnectionID);
  }

  async loadAPIData(): Promise<void> {
    Vue.set(this, 'livesplitData', (await apiGETMulti('livesplitData', {}, true)).data);
  }

  created(): void {
    this.loadAPIData();
  }

  async forceDisconnect(): Promise<void> {
    if (this.selectedConnectionID !== null) {
      try {
        await axios.post(`/livesplit/disconnect/${this.selectedConnectionID}`, null, axiosConfig());
        // this POST returns true/false depending on if it worked or not, we should use it!
      } catch (err) {
        // error
      }
    }
  }
}
